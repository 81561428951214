import { formatDate } from "@/utils/datetime.js";

const getScheduledEmailCampaignListAPI = (
    emarketingId,
    fireAtFrom = null,
    fireAtTo = null,
    queryType = null
) => {
    const fromParam = fireAtFrom ? `&fire_at_from=${formatDate.longISO(fireAtFrom)}` : "";
    const toParam = fireAtTo ? `&fire_at_to=${formatDate.longISO(fireAtTo)}` : "";
    const queryTypeParam = queryType ? `&query_type=${queryType}` : "";
    const allParams = [fromParam, toParam, queryTypeParam].filter((p) => p).join("");
    return `/api/scheduled_email_campaign/?emarketing=${emarketingId}${allParams}`;
};

const getScheduledPostingListAPI = (
    pageId,
    provider = null,
    status = null,
    deleted = null,
    fireAtFrom = null,
    fireAtTo = null,
    createdFrom = null,
    createdTo = null,
    top = null,
    ordering = null,
    page = null
) => {
    const providerParam = provider !== null ? `&provider=${provider}` : "";
    const statusParam = status !== null ? `&status=${status}` : "";
    const fromParam = fireAtFrom ? `&fire_at_from=${formatDate.longISO(fireAtFrom)}` : "";
    const toParam = fireAtTo ? `&fire_at_to=${formatDate.longISO(fireAtTo)}` : "";
    const deletedParam = deleted !== null ? `&deleted=${deleted}` : "";
    const orderingParam = ordering && !top ? `&ordering=${ordering}` : "";
    const createdFromParam = createdFrom ? `&created_from=${formatDate.longISO(createdFrom)}` : "";
    const createdToParam = createdTo ? `&created_to=${formatDate.longISO(createdTo)}` : "";
    const topParam = top ? `&top=${top}` : "";
    const pageParam = page ? `&page=${page}` : "";
    const allParams = [
        providerParam,
        statusParam,
        deletedParam,
        fromParam,
        toParam,
        createdFromParam,
        createdToParam,
        topParam,
        orderingParam,
        pageParam,
    ]
        .filter((p) => p)
        .join("");
    return `/api/scheduled_postings/?pgid=${pageId}${allParams}`;
};

const getPostLibraryListAPI = (
    profileId,
    search = null,
    campaigns = null,
    travel_types = null,
    destinations = null
) => {
    const searchParam = search !== null ? `&search=${search}` : "";
    const campaignsParam =
        campaigns && campaigns.length ? campaigns.map((id) => `&campaign=${id}`).join("") : "";
    let travelTypesParam =
        travel_types && travel_types.length
            ? travel_types.map((type) => `&travel_type=${encodeURIComponent(type)}`).join("")
            : "";
    let destinationsParam =
        destinations && destinations.length
            ? destinations.map((dest) => `&destination=${encodeURIComponent(dest)}`).join("")
            : "";
    const allParams = [searchParam, campaignsParam, travelTypesParam, destinationsParam]
        .filter((p) => p)
        .join("");
    return `/api/post_library/?profile=${profileId}${allParams}`;
};

const getBillingSyncAPI = (user_pk, sync = null, refresh = null) => {
    let params = [];
    const syncParam = sync ? `sync=true` : "";
    const refreshParam = refresh ? `refresh=${refresh}` : "";
    params = [syncParam, refreshParam].filter((p) => p).join("&");
    const billingURL = `/api/billing/${user_pk}/`;
    return params ? `${billingURL}?${params}` : billingURL;
};

const getPostingAPI = (campaign_pk = null, travel_types = null, destinations = null) => {
    const campaignParam = campaign_pk ? `campaign=${campaign_pk}` : "";
    const travelTypesParam =
        travel_types && travel_types.length
            ? travel_types.map((type) => `travel_type=${encodeURIComponent(type)}`).join("&")
            : "";
    const destinationsParam =
        destinations && destinations.length
            ? destinations.map((dest) => `destination=${encodeURIComponent(dest)}`).join("&")
            : "";
    const allParams = [campaignParam, travelTypesParam, destinationsParam].filter((p) => p).join("&");
    return `/api/postings/?ordering=fire_at,id&${allParams}`;
};

export const endpoints = {
    "profilesocial-status": (profileId, appSlug) => `/api/profile_social/${profileId}/${appSlug}/status/`,
    "profilesocial-accountdata": (profileId, appSlug) =>
        `/api/profile_social/${profileId}/${appSlug}/account_data/`,
    "profilesocial-url": (profileId, appSlug) => `/api/profile_social/${profileId}/${appSlug}/url/`,
    "profilesocial-pages": (profileId, appSlug) => `/api/profile_social/${profileId}/${appSlug}/pages/`,
    "profilesocial-select": (profileId, appSlug, pageId) => {
        return `/api/profile_social/${profileId}/${appSlug}/select/?id=${pageId}`;
    },
    "post-library": getPostLibraryListAPI,
    "post-library-schedule": (profileId, postId) =>
        `/api/post_library/${postId}/schedule/?profile=${profileId}`,
    "campaign-library": (profile_pk) => `/api/campaign_library/?profile=${profile_pk}`,
    "scheduledemailcampaign-list": getScheduledEmailCampaignListAPI,
    "scheduledposting-list": getScheduledPostingListAPI,
    announcements_list: (profile_pk) => `/api/announcements/?profile=${profile_pk}`,
    onboarding_videos_list: (profile_pk) => `/api/onboarding_videos/?profile=${profile_pk}`,
    billing: getBillingSyncAPI,
    "billing-create": "/api/billing/",
    "billing-address-options": "/api/billing/address_options/",
    "plan-list": "/api/plan/",
    "superuser-downgrade": (userId) => `/api/users/${userId}/downgrade/`,
    "cobrandedsupplier-list": (profileId) => `/api/cobrandedsuppliers/?profile=${profileId}`,
    "cobrandedprofilesupplier-update": (profileSupplierId, profileId, supplierId) =>
        `/api/cobrandedprofilesupplier/${profileSupplierId}/?profile=${profileId}&supplier=${supplierId}`,
    generator_profile: "/api/generator_profile/",
    generator_profile_detail: (profile_pk) => `/api/generator_profile/${profile_pk}/`,
    generator_profile_create: (profile_pk) => `/api/generator_profile/${profile_pk}/create_post/`,
    generated_postings: (profile_pk) =>
        `/api/generated_postings/?generator_profile=${profile_pk}&scheduled=false`,
    generated_postings_detail: (generated_posting_pk) => `/api/generated_postings/${generated_posting_pk}/`,
    generated_postings_schedule: (generated_posting_pk) =>
        `/api/generated_postings/${generated_posting_pk}/schedule/`,
    profile_details: (profile_pk) => `/api/profiles/${profile_pk}/`,
    profile_destinations: () => `/api/profiles/destinations/`,
    profile_advanced_features: (profile_pk) => `/api/profiles/${profile_pk}/advanced_features/`,
    tutorial_videos: (app_slug) => `/api/tutorial_videos/?application__slug=${app_slug}`,
    // Common
    travel_types: "/api/travel_types/",
    destinations: "/api/destinations/",
    agency_host_name: (country_pk) => `/api/agency_host_name/?country=${country_pk}`,
    countries: "/api/countries/",
    states: (country_pk) => `/api/states/?country=${country_pk}`,
    license_option: "/api/license_option/",
    // Admin
    postings: getPostingAPI,
    "postings-edit": (posting_pk) => `/api/postings/${posting_pk}/`,
    "postings-reschedule": (posting_pk) => `/api/postings/${posting_pk}/reschedule/`,
};
