<template>
    <div class="mb-4">
        <article class="card card--shadow-border mb-4 socialpost">
            <section class="card-body socialpost--body">
                <section class="d-flex justify-content-between mb-2">
                    <div>
                        <div class="d-flex">
                            <div
                                v-if="pageImage"
                                class="align-self-center mr-2"
                            >
                                <img
                                    :src="pageImage"
                                    height="48"
                                    alt=""
                                    class="socialpost--image"
                                />
                            </div>
                            <div>
                                <h3 class="h5 mb-0 mt-1 socialpost--name">{{ pageName }}</h3>
                                <post-status-label :post="post"></post-status-label>
                            </div>
                        </div>
                    </div>
                    <post-actions
                        :post="post"
                        @add-item="addItem"
                        @update-item="updateItem"
                        refresh-parent="updateUpcomingCol"
                    ></post-actions>
                </section>

                <p
                    class="text-breakspaces mb-0"
                    v-html="message"
                ></p>
            </section>
            <main class="card-footer card-footer--no-padding card-footer--no-link">
                <video
                    v-if="post.video"
                    :src="post.video"
                    :poster="post.image"
                    controls
                    controlsList="nodownload"
                    class="embed-responsive embed-responsive-21by9 post-video"
                ></video>

                <div
                    v-if="!post.video && post.image"
                    class="overflow-hidden"
                    :class="anyStats(post) ? '' : 'rounded-bottom'"
                >
                    <a
                        v-if="post.external_link || post.link"
                        :href="post.external_link || post.link"
                        target="_blank"
                        :title="post.link_title"
                    >
                        <img
                            v-lazy="{ src: post.image }"
                            alt=""
                            class="img-fluid"
                        />
                        <div
                            v-if="post.domain || post.title"
                            :class="anyStats(post) ? '' : 'rounded-bottom'"
                            class="border"
                        >
                            <p
                                v-if="post.domain"
                                class="px-3 pt-2 pb-1 mb-0 text-uppercase"
                            >
                                <small>{{ post.domain }}</small>
                            </p>
                            <p
                                v-if="post.title"
                                class="mb-0 px-3 pb-3 h5"
                            >
                                {{ post.title }}
                            </p>
                        </div>
                    </a>
                    <post-image :post="post"></post-image>
                </div>

                <div
                    v-if="anyStats(post)"
                    class="bg-white rounded-bottom border-right border-left border-bottom p-3"
                >
                    <div class="d-flex justify-content-between px-3">
                        <div
                            v-for="stat in statsOptions"
                            :key="stat.field"
                        >
                            <h4 class="h6 mb-0">{{ post.stats[stat.field] }}</h4>
                            <p class="mb-0 small">{{ $t(stat.label) }}</p>
                        </div>
                    </div>
                </div>
            </main>
        </article>
    </div>
</template>

<script>
    import PostActions from "./PostActions.vue";
    import PostStatusLabel from "./PostStatusLabel.vue";
    import { appStringProp } from "@/utils/props.js";
    import { formatDate } from "@/utils/datetime.js";
    import { markHashTags } from "@/utils/hashtags";
    import { useMainStore } from "@/main-store";
    import PostImage from "./PostImage.vue";
    import { connectionConfigValues } from "@/components/apps-connection-config";

    export default {
        components: { PostActions, PostStatusLabel, PostImage },
        name: "PostSocial",
        emits: ["add-item", "update-item"],
        props: {
            app: appStringProp,
            item: {
                type: Object,
                required: true,
            },
        },
        data: function () {
            return {
                post: this.parseItem(this.$props.item),
                mainStore: useMainStore(),
            };
        },
        methods: {
            parseItem(item) {
                if (item && item.fire_at) {
                    item.fire_at = formatDate.parseWithTimeZone(item.fire_at);
                    item.fire_at_formatted = item.fire_at.format("LLLL");
                }
                return item;
            },
            updateItem(post) {
                this.post = this.parseItem(post);
                this.$emit("update-item", this.post);
            },
            addItem(post) {
                this.$emit("add-item", post);
            },
            anyStats(post) {
                return Object.keys(post.stats).length > 0;
            },
        },
        computed: {
            appData() {
                return this.mainStore[`${this.app}_data`];
            },
            pageName() {
                return this.appData.name || "-";
            },
            pageImage() {
                return this.appData.image || "-";
            },
            message() {
                return markHashTags(this.post.messages_with_custom_text, false);
            },
            statsOptions() {
                return connectionConfigValues[this.app].stats;
            },
        },
    };
</script>
