<template>
    <div
        class="event-card card"
        :class="[enabledClass, highlightedClass]"
    >
        <div
            class="d-flex justify-content-between mt-1"
            :class="`text-${eventInfo.app}`"
        >
            <span class="event-card-time ml-1">{{ event.timeText }}</span>
            <span class="mr-1">
                <i
                    class="fa mr-1"
                    :class="statusIcon"
                ></i>
                <i
                    class="fa"
                    :class="icon"
                ></i>
            </span>
        </div>
        <span class="event-card-title ml-1 mr-1">{{ event.event.title }}</span>
        <post-image
            :post="eventInfo.post"
            class="event-card-image p-1"
        ></post-image>
    </div>
</template>

<script>
    import { connectionConfigValues } from "@/components/apps-connection-config.js";
    import PostImage from "@/components/post/PostImage.vue";
    export default {
        name: "CalendarEvent",
        components: { PostImage },
        props: {
            event: {
                type: Object,
                required: true,
            },
        },
        computed: {
            eventInfo() {
                return this.event.event.extendedProps;
            },
            enabledClass() {
                return this.eventInfo.enabled ? "event-card-enabled" : "event-card-disabled";
            },
            highlightedClass() {
                return this.eventInfo.highligthed ? "event-card-highlighted" : "";
            },
            icon() {
                return connectionConfigValues[this.eventInfo.app].icon;
            },
            statusIcon() {
                let icon = "";
                const status = this.eventInfo.post.status;
                // Deleted
                if ([30, 40, 45, 50].includes(status)) {
                    icon = "fa-trash";
                }
                // Pending
                if (status === 0 || status === "pending") {
                    icon = "fa-clock-o";
                }
                // Posted
                if (status === 10 || status === "published") {
                    icon = "fa-send";
                }
                return icon;
            },
        },
    };
</script>
